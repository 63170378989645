export enum RiotRoutingRegion {
  AMERICAS = "AMERICAS",
  ASIA = "ASIA",
  EUROPE = "EUROPE",
  SEA = "SEA"
}

export enum RiotPlatformRegion {
  GLOBAL = "GLOBAL",
  BRAZIL = "BR1",
  EUROPE_NORTH = "EUN1",
  EUROPE_WEST = "EUW1",
  JAPAN = "JP1",
  KOREA = "KR",
  LATIN_AMERICA_NORTH = "LA1",
  LATIN_AMERICA_SOUTH = "LA2",
  MIDDLE_EAST = "ME1",
  NORTH_AMERICA = "NA1",
  OCEANIA = "OC1",
  TURKEY = "TR1",
  RUSSIA = "RU",
  PHILIPPINES = "PH2",
  SINGAPORE = "SG2",
  THAILAND = "TH2",
  TAIWAN = "TW2",
  VIETNAM = "VN2"
}

export enum RiotRankedTier {
  CHALLENGER = "CHALLENGER",
  GRANDMASTER = "GRANDMASTER",
  MASTER = "MASTER",
  DIAMOND = "DIAMOND",
  EMERALD = "EMERALD",
  PLATINUM = "PLATINUM",
  GOLD = "GOLD",
  SILVER = "SILVER",
  BRONZE = "BRONZE",
  IRON = "IRON"
}

export enum RiotRankedDivision {
  I = "I",
  II = "II",
  III = "III",
  IV = "IV"
}

export enum RankedQueue {
  RANKED_TFT = "RANKED_TFT",
  RANKED_TFT_TURBO = "RANKED_TFT_TURBO"
}

export enum QueueID {
  NORMAL = 1090,
  RANKED = 1100,
  TUTORIAL = 1110,
  HYPER_ROLL = 1130,
  DOUBLE_UP = 1160,
  CHONCCS_TREASURE = 1190,
  CHONCCS_TREASURE_2 = 1210,
  TOCKERS_TRIALS = 1220,
  NORMAL_2 = 2200,
  SET_3_REVIVAL = 6000,
  SET_5_REVIVAL = 6110,
  PENGUS_PARTY = 6120
}